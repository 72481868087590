<template>
  <b-card>
    <validation-observer ref="accountRules" tag="form">
      <b-form class="mt-1" @submit.prevent>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              Data Anggota
            </h5>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="NISN" rules="required">
              <b-form-group label="NISN" label-for="nisn" :state="errors.length > 0 ? false : null">
                <v-select id="nisn" v-model="dataParent.nisn" :reduce="(nisn) => nisn.nisn" placeholder="Pilih Siswa"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listSiswa" label="name"
                  @change="onChange($event)" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="Nama" label-for="name">
              <validation-provider #default="{ errors }" name="name" rules="required">
                <b-form-input id="name" v-model="dataParent.name"
                  :state="errors.length > 0 ? false : null" placeholder="Nama" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="ID Anggota" label-for="librarynumber">
              <validation-provider #default="{ errors }" name="ID Anggota" rules="required">
                <b-form-input id="librarynumber" v-model="dataParent.librarynumber"
                  :state="errors.length > 0 ? false : null" placeholder="ID Anggota" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Email" label-for="email">
              <validation-provider #default="{ errors }" name="email" rules="required">
                <b-form-input id="email" v-model="dataParent.email" :state="errors.length > 0 ? false : null"
                  placeholder="Email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="No TLP/WA" label-for="handphone">
              <validation-provider #default="{ errors }" name="No TLP/WA" rules="required">
                <b-form-input id="handphone" v-model="dataParent.handphone" :state="errors.length > 0 ? false : null"
                  placeholder="No TLP/WA" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Notes" label-for="notes">
              <validation-provider #default="{ errors }" name="notes" rules="required">
                <b-form-input id="notes" v-model="dataParent.notes" :state="errors.length > 0 ? false : null"
                  placeholder="Notes" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row class="text-right">
          <b-col>
            <!-- submit and reset -->
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
              @click="formSubmitted">
              Submit
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
              @click="() => $router.go(-1)">
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      required,
      email,
      size,
      dataParent: {
        nisn: '',
        librarynumber: '',
        email: '',
        handphone: '',
        notes: '',
        active: 1,
        name:''
      },
      listSiswa: [],
    }
  },
  computed: {

  },
  created() {
    this.getSiswa()
  },
  methods: {
    onChange(event) {
      console.log(event.target.value)
    },
    async getSiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })        
        await this.$http.post('/librarymembers', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'perpus-anggota' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
